import React from 'react'
import WKLogo from "./wk-logo"
import { Button } from "react-bootstrap"
import SEO from "./seo"

import { useGoogleAuth } from './GoogleAuthProvider'

export default function Login() {
  const { signIn } = useGoogleAuth()
  if (typeof document !== `undefined`) {
    let ufo = document.querySelector('body')

    ufo.addEventListener('mousemove', (e) => {
      if(document.querySelector('.eyes')!==null){
        let eyes = document.querySelector('.eyes')
        let mouseX = (eyes.getBoundingClientRect().left)
        let mouseY = (eyes.getBoundingClientRect().top)
        let radianDegrees = Math.atan2(e.pageX - mouseX, e.pageY - mouseY)
        let rotationDegrees = (radianDegrees * (180/ Math.PI) * -1) + 180
        eyes.style.transform = `rotate(${rotationDegrees}deg)`
      }
    })
  }
  return (
    <>
      <SEO title="Login" />
      <div className="sign-in-wrap">
        <div className="logo"><WKLogo /></div>
        <div className="sign-in">
          <div className="wrap">
            <div className="icon">
              <div className="ufo">
                <div className="monster">
                  <div className="body">
                    <div className="ear"></div>
                    <div className="ear"></div>
                    <div className="vampi-mouth">
                      <div className="vampi-tooth"></div>
                    </div>
                  </div>
                  <div className="eye-lid">
                    <div className="eyes">
                      <div className="eye"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <small>Authorize app from your WK email ID.</small>
            <div className="button-wrap">
              <Button onClick={() => signIn()} size="lg" variant="outline-primary">Sign in</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
