import React from "react"

import { useGoogleAuth } from '../components/GoogleAuthProvider'
import Header from "../ui/header"
import Footer from "../ui/footer"
import Login from "./Login"

const Layout = ({ children }) => {
  const { isSignedIn } = useGoogleAuth()
  const renderLayout = !isSignedIn ? <Login /> : <><Header /> <main>{children}</main></>
  // const renderLayout = <><Header /> <main>{children}</main></>
  
  return(
  <>
    {renderLayout}
    <Footer />
  </>
)}

export default Layout