import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import NavItem from './NavItem'

export default function NavMain(props) {
    return (
        <nav className="nav-main">
            <div className="wrap">
                <Container>
                    <Row>
                        <Col>
                            <NavItem />
                        </Col>
                    </Row>
                </Container>
            </div>
        </nav>
    )
}
