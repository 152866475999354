import { Link } from 'gatsby'
import React from 'react'

export default function NavItem() {
    return (
        <ul className="nav-main__items">
            <li><Link activeClassName="active" to="/who-we-are">Who we are</Link></li>
            <li><Link activeClassName="active" to="/the-cheat-sheet">The Cheat Sheet</Link></li>
            <li><Link activeClassName="active" to="/learn-and-grow">Learn & Grow</Link></li>
            <li><Link activeClassName="active" to="/other-essentials">Other Essentials</Link></li>
            <li><Link activeClassName="active" to="/whats-happening">What's Happening</Link></li>
        </ul>
    )
}
