import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export default function Footer() {
    return (
        <footer>
            <Container>
                <Row>
                    <Col md={4}>&copy; {new Date().getFullYear()}, All rights reserved</Col>
                    <Col md={{ span: 4, offset: 4 }} className="text-right social-links">
                        <a href="https://www.facebook.com/wkdelhi/" rel="noreferrer" target="_blank">Facebook</a>
                        <a href="https://twitter.com/wkdelhi" rel="noreferrer" target="_blank">Twitter</a>
                        <a href="https://www.instagram.com/wk_delhi/" rel="noreferrer" target="_blank">Instagram</a>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
