import React from 'react'
import { FaPowerOff } from 'react-icons/fa'
import { useGoogleAuth } from '../components/GoogleAuthProvider'

export default function Burger(props) {
    const { signOut } = useGoogleAuth()
    return (
        <div className="navIcon">
            <ul className="hamburger" onClick={props.onClick} role="presentation">
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <FaPowerOff onClick={signOut} alt="Logout" title="Logout" role="presentation" />
        </div>
    )
}
