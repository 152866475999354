import React, { useState, useEffect, useRef }  from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Burger from "./Burger"
import WKLogo from "../components/wk-logo"
import NavMain from "./NavMain"
import { useGoogleAuth } from '../components/GoogleAuthProvider'

const Header = () => {
  const [navState, setnavState] = useState('nav-closed')
  const { signOut } = useGoogleAuth()
  function navOpenState(){
    if(navState === `nav-closed`){
      setnavState('nav-open')
    }
    else{
      setnavState('nav-closed')
    }
  }
  const [isSticky, setSticky] = useState(false)
  const ref = useRef(null)
  const handleScroll = () => {
    if(window.pageYOffset >= 100){
      setSticky(true)
    }
    else{
      setSticky(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);
  return (
  <header className={navState === `nav-open` ? `nav-open` : `` || isSticky ? `solid` : ``} ref={ref}>
    <div className="logo"><Link to="/"><WKLogo /></Link></div>
    <Burger onClick={() => navOpenState()} onClickLogout={signOut} />
    <NavMain />
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
